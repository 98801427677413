import r from"styled-components";import{color as e,typography as o}from"../../../shared/styles.js";const t="ZUINotification",i={info:e.blueLighter,success:e.greenLight,warning:e.yellowLight,error:e.redLight,normal:"white"},a={info:e.bluePrimary,success:e.green,warning:e.dark,error:e.red,normal:e.dark},l={info:`2px solid ${e.blueDark}`,success:`2px solid ${e.green}`,warning:`2px solid ${e.yellowDark}`,error:`2px solid ${e.red}`,normal:"unset"},n=r.div`
  position: relative;
  display: flex;
  flex-direction: ${r=>"large"===r.layout?"column":"row"};
  ${r=>"large"===r.layout?"justify-content: flex-start;":"align-items: flex-start;"}

  width: ${r=>r.fitParent?"100%":"fit-content"};
  padding: ${r=>"large"===r.layout?"16px":"12px 16px"};
  padding-right: ${r=>"small"===r.layout&&r.hasClose?"42px":"16px"};
  background-color: ${r=>i[r.type]};
  border: ${({border:r,type:e})=>r?l[e]:"unset"};
  border-radius: 4px;
  box-sizing: border-box;

  ${r=>"normal"===r.type&&"box-shadow: 0px 8px 16px rgba(29, 35, 58, 0.1);"}

  .${t}__icon {
    width: 24px;
    ${r=>"large"===r.layout?"margin-bottom: 8px;":"margin-right: 8px;"}
  }

  .${t}__message {
    font-family: ${o.type.primary};
    font-size: ${o.size.s3};
    font-weight: ${o.weight.medium};
    line-height: 24px;
    color: ${r=>a[r.type]};

    b {
      font-weight: ${o.weight.medium};
    }
  }

  .${t}__container {
    p {
      margin: 5px 0 0;
      font-weight: 500;
      color: #5c7beb;
    }
  }

  .${t}__close {
    position: absolute;
    top: ${r=>"large"===r.layout?"18px":"50%"};
    right: ${r=>"large"===r.layout?"18px":"16px"};
    transform: ${r=>"large"===r.layout?"unset":"translateY(-9px)"};
    cursor: pointer;
  }

  .${t}__button {
    margin-top: ${({layout:r})=>"large"===r?"16px":"0"};
    margin-left: ${({layout:r})=>"large"===r?"0":"16px"};
  }
`;export{t as COMP_NAME,n as Container};
