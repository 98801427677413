import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'login';

export const loginStyle = css`
  .login {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 12px 32px rgba(29, 35, 58, 0.1);
    border-radius: 20px 20px 0 0;
    padding: 26px 32px;
    width: 100%;
    height: 80%;
    $self: &;

    &--keyboard-open {
      #{$self}__login-button {
        margin: 0;
      }
    }

    h3 {
      margin: 0;
      font-weight: ${typography.weight.medium};
      font-size: ${typography.size.m2};
      line-height: 24px;
      color: ${color.darker};
    }

    &__description {
      font-size: ${typography.size.s3};
      line-height: 19px;
      color: ${color.dark};
      margin: 16px 0 24px;
    }

    &__forgot-link {
      display: none;
      margin: 0;
      padding: 0;
      width: fit-content;
      cursor: pointer;
      font-size: ${typography.size.s3};
      line-height: 19px;
      font-weight: ${typography.weight.medium};
      color: ${color.dark};

      &--mobile {
        display: block;
      }
    }

    &__login-button {
      margin: auto 0 0;
      width: 100%;
    }

    &__no-user {
      margin: 18px auto 0;
      width: fit-content;
      font-size: ${typography.size.s3};
      line-height: 19px;
      color: ${color.dark};

      a {
        color: ${color.bluePrimary};
        font-weight: ${typography.weight.medium};
      }
    }
  }

  @media screen and (min-width: 768px) {
    .login {
      border-radius: 10px;
      padding: 36px 54px;
      max-width: 464px;
      height: 486px;

      h3 {
        font-size: ${typography.size.l1};
        line-height: 38px;
      }

      &__forgot-link {
        position: absolute;
        bottom: 46px;
        right: 0;
        left: 0;
        display: block;
        margin: 0 auto;
        padding: 2px 15px;

        &--mobile {
          display: none;
        }

        &:hover {
          background-color: ${color.blueLighter};
          border-radius: 12px;
        }

        &:active {
          background-color: ${color.blueLight};
        }
      }

      &__login-button {
        margin: 0;
      }

      &__no-user {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(50px);
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .login {
      padding: 48px 72px;
      max-width: 504px;
    }
  }
`;
