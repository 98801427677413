import auth0JS from '../../../utils/auth0Utils/auth0js';

export interface UseLoginProps {
  email: string;
  password: string;
  redirectTo?: string;
  authorizeUrl: string;
  onFailCallback?: () => void;
}

export const onLogin = async ({
  email,
  password,
  redirectTo,
  authorizeUrl,
  onFailCallback,
}: UseLoginProps): Promise<void> => {
  // NOTE: Passing true as the third parameter to `auth0JS` will add the `match` query param to the redirect URL
  const {WebAuth, nonce} = auth0JS({authorizeUrl, redirectTo});

  WebAuth.login(
    {
      email,
      password,
      nonce,
    },
    () => {
      onFailCallback();
    },
  );
};

export const clearAuth0Cookies = (): void => {
  const cookies = document.cookie.split(';');

  // Force expiration for all Auth0 cookies
  cookies.forEach((cookie) => {
    if (cookie.includes('com.auth0.auth')) {
      document.cookie = `${cookie}=; expires=${new Date(0).toUTCString()}`;
    }
  });
};
