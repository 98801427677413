import React, {FC, useRef, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useRouter} from 'next/router';

import {Button, Input} from '@zoefin/design-system';

import request from '../../../../../utils/request';
import {clearAuth0Cookies, onLogin} from '../../utils';

import {COMP_NAME, loginStyle} from './styles';

interface SearchProspect {
  is_in_plutus: boolean;
  has_set_password: boolean;
}

interface LoginFormProps {
  onCreatePassword: (email: string) => void;
  onForgotPassword: () => void;
}

type LoginState = {
  email: string;
  password: string;
};

type ErrorType = 'email' | 'password';

// It's an approximated value. This might not always be correct
const MIN_KEYBOARD_HEIGHT = 300;
const MAX_MOBILE_WIDTH = 768;

const LoginForm: FC<LoginFormProps> = ({
  onForgotPassword,
  onCreatePassword,
}) => {
  const {loginWithRedirect} = useAuth0();
  const {query, push} = useRouter();

  const [loginState, setLoginState] = useState<LoginState>({
    email: '',
    password: '',
  });

  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [hasPassword] = useState<boolean>(false);
  const [hasError, setHasError] = useState<ErrorType>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  const handleEmailChange = (value: string) => {
    setHasError(undefined);
    setLoginState({...loginState, email: value});
  };

  const onCheckEmail = () => {
    setLoading(true);
    request(`/api/search/user`, {
      method: 'POST',
      body: {value: loginState.email},
    })
      .then((res: SearchProspect) => {
        if (res.has_set_password && !res.is_in_plutus) {
          // Redirecting to universal
          push({
            pathname: `/api/auth/login`,
            query: {
              login_hint: loginState.email,
              ...(query.returnTo && {returnTo: query.returnTo}),
            },
          });

          return;
        }

        if (res.has_set_password && res.is_in_plutus) {
          // redirecting to dashboard
          const returnTo = new URL(
            `${process.env.DASHBOARD_HOST}/api/auth/login`,
          );

          returnTo.searchParams.append('login_hint', loginState.email);

          // forcing logout current session
          push({
            pathname: `/api/auth/logout`,
            query: {
              login_hint: loginState.email,
              ...(returnTo && {returnTo: String(returnTo)}),
            },
          });

          return;
        }

        onCreatePassword(loginState.email);
      })
      .catch(() => {
        setHasError('email');
        setLoading(false);
      });
  };

  const onLogIn = async () => {
    setLoading(true);

    let authorizeUrl: string;

    await loginWithRedirect({
      openUrl(url) {
        authorizeUrl = url;
      },
    }).catch((err) => err);

    await onLogin({
      email: loginState.email,
      password: loginState.password,
      redirectTo: query.returnTo as string,
      authorizeUrl,
      onFailCallback: () => {
        setLoading(false);
        setHasError('password');
      },
    });
  };

  const handleSubmit = () => {
    // Clear Auth0 cookies
    clearAuth0Cookies();

    if (!hasPassword) {
      onCheckEmail();
    } else {
      onLogIn();
    }
  };

  const listener = () => {
    const isMobile = window.innerWidth < MAX_MOBILE_WIDTH;

    setIsKeyboardOpen(
      isMobile &&
        window.screen.height - MIN_KEYBOARD_HEIGHT >
          window.visualViewport.height,
    );
  };

  if (typeof window !== 'undefined') {
    window.visualViewport.addEventListener('resize', listener);
  }

  return (
    <>
      <div
        className={`${COMP_NAME} ${COMP_NAME}${
          isKeyboardOpen ? '--keyboard-open' : ''
        }`}>
        <h3>Log In</h3>

        <p className={`${COMP_NAME}__description`}>
          Log in to your account with your email address.
        </p>

        <Input
          id="email-input"
          type="email"
          label="Email"
          value={loginState.email}
          validator={(_newVal, isValid) => isValid}
          onValidationChange={(isValid) => {
            setIsValidEmail(isValid);
          }}
          onChange={handleEmailChange}
          onKeyUp={(code) => {
            if (code === 'Enter' && loginState.email) {
              onCheckEmail();
            }
          }}
          errorMessage={hasError === 'email' ? 'Invalid email' : ''}
          hasError={hasError === 'email'}
          fitParent
          required
        />

        {hasPassword && (
          <Input
            id="password"
            label="Password"
            value={loginState.password}
            iconPosition="right"
            type="password"
            validator={(_newVal, isValid) => _newVal?.length > 7 && isValid}
            onChange={(value) =>
              setLoginState({...loginState, password: value})
            }
            style={{width: '100%', maxWidth: 'initial'}}
            errorMessage={hasError === 'password' ? 'Invalid password' : ''}
            hasError={hasError === 'password'}
            onKeyDown={onLogIn}
            fitParent
            required
            innerRef={passwordInputRef}
          />
        )}

        <a
          className={`${COMP_NAME}__forgot-link ${COMP_NAME}__forgot-link--mobile`}
          onClick={onForgotPassword}>
          Forgot Your Password?
        </a>

        <div className={`${COMP_NAME}__login-button`}>
          <Button
            disabled={!isValidEmail || (hasPassword && !loginState.password)}
            config={{
              color: 'blue',
              type: 'primary',
            }}
            loading={loading}
            onClick={handleSubmit}
            style={{width: '100%', marginTop: '20px'}}
            as="button">
            {hasPassword ? 'Log In' : 'Continue'}
          </Button>
        </div>

        <a className={`${COMP_NAME}__forgot-link`} onClick={onForgotPassword}>
          Forgot Your Password?
        </a>

        <p className={`${COMP_NAME}__no-user`}>
          Not a user yet?{' '}
          <a onClick={() => push('/onboarding')}> Get Started</a>
        </p>
      </div>
      <style jsx>{loginStyle}</style>
    </>
  );
};

export default LoginForm;
