import React, {FC, useState} from 'react';
import {getAccessToken} from '@auth0/nextjs-auth0';
import {GetServerSidePropsContext} from 'next';
import Head from 'next/head';
import Image from 'next/image';
import {useRouter} from 'next/router';

import {Button} from '@zoefin/design-system';

import {getFromPageContext} from '../../../utils/pageUtils';
import request from '../../../utils/request';

import {
  CheckEmail,
  ExpiredToken,
  LoginForm,
  RecoverPassword,
  ResetPassword,
  ZoeBanner,
} from './components';
import {
  COMP_NAME,
  loginPasswordGlobalStyle,
  loginPasswordStyle,
} from './styles';

export const enum LoginType {
  LOGIN = 'login',
  RECOVER = 'recover',
  RESET = 'reset',
  SUBMITTED = 'submitted',
  EXPIRED_TOKEN = 'expired',
}

type TokenErrorTypes = 'HAS_PASSWORD' | 'NEW_EMAIL' | 'EMAIL_NOT_FOUND';

export const TOKEN_ERRORS: Record<TokenErrorTypes, string> = {
  HAS_PASSWORD:
    'Token expired. The user already set a password, no need to resend it',
  NEW_EMAIL: 'Token expired. A new email has been sent',
  EMAIL_NOT_FOUND:
    'Token expired. Previous email not found. It was not possible to resend the token',
};

interface TokenValidationBody {
  token: string;
  email: string;
  source?: string;
}

type LoginStep = {
  [key in LoginType]: React.ReactElement;
};
interface BackButtonProps {
  hideMobile?: boolean;
  onClick: () => void;
}

const BackButton: FC<BackButtonProps> = ({
  hideMobile,
  onClick,
}): React.ReactElement => (
  <div
    className={`${COMP_NAME}__back ${
      hideMobile ? `${COMP_NAME}__back--hide` : ''
    }`}>
    <Button
      onClick={onClick}
      config={{
        color: 'blue',
        type: 'tertiary',
      }}
      as="button"
      withIcon={{
        iconSrc: '/static/svg/icon_anchor-left-gray.svg',
        iconPosition: 'left',
      }}>
      Back
    </Button>
  </div>
);

BackButton.defaultProps = {
  hideMobile: false,
};

const ZoeIcon = (className?: string): React.ReactElement => (
  <div className={`${COMP_NAME}__zoe-icon ${className}`}>
    <Image
      src="/static/svg/zoe_logo_dark_blue.svg"
      alt="Zoe logo"
      width={91}
      height={40}
    />
  </div>
);
export interface LoginProps {
  type:
    | LoginType.RESET
    | LoginType.SUBMITTED
    | LoginType.LOGIN
    | LoginType.EXPIRED_TOKEN;
}
const LoginPassword: FC<LoginProps> = ({type}) => {
  const router = useRouter();
  const [step, setStep] = useState<LoginType>(type || LoginType.LOGIN);
  const [email, setEmail] = useState<string>();

  const showCreatePassword =
    router.asPath.includes('set_password_token') ||
    router.pathname.includes('set-password');

  const loginSteps: LoginStep = {
    [LoginType.LOGIN]: (
      <>
        <ZoeBanner />
        <LoginForm
          onCreatePassword={(newEmail) => {
            setEmail(newEmail);
            setStep(LoginType.SUBMITTED);
          }}
          onForgotPassword={() => setStep(LoginType.RECOVER)}
        />
      </>
    ),
    [LoginType.RECOVER]: (
      <>
        <BackButton onClick={() => setStep(LoginType.LOGIN)} />
        {ZoeIcon()}
        <RecoverPassword onResetPassword={() => setStep(LoginType.LOGIN)} />
      </>
    ),
    [LoginType.RESET]: (
      <>
        {ZoeIcon(`${COMP_NAME}__zoe-icon--left-mobile`)}
        {ZoeIcon()}
        <ResetPassword
          isCreate={showCreatePassword}
          onReset={() => setStep(LoginType.LOGIN)}
        />
      </>
    ),
    [LoginType.SUBMITTED]: (
      <>
        <BackButton onClick={() => setStep(LoginType.LOGIN)} hideMobile />
        {ZoeIcon(`${COMP_NAME}__zoe-icon--left-mobile`)}
        {ZoeIcon()}
        <CheckEmail email={email} />
      </>
    ),

    [LoginType.EXPIRED_TOKEN]: <ExpiredToken />,
  };
  const title: string = {
    [LoginType.LOGIN || LoginType.SUBMITTED]: 'Log In',

    [LoginType.RESET]: showCreatePassword
      ? 'Create Password'
      : 'Reset Password',
    [LoginType.EXPIRED_TOKEN]: 'Expired Token',
  }[step];

  return (
    <>
      <Head>
        <title>Zoe Financial | {title}</title>
      </Head>
      ​<main className={COMP_NAME}>{loginSteps[step]}</main>​
      <style jsx>{loginPasswordStyle}</style>
      <style jsx>{loginPasswordGlobalStyle}</style>
    </>
  );
};
export default LoginPassword;
export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const {query, baseURL, headers, resolvedUrl} = getFromPageContext(ctx);
  const {set_password_token, token, email, returnTo} = query;
  const createPassFlow = resolvedUrl.includes('set_password_token');
  const {accessToken} = await getAccessToken(ctx.req, ctx.res).catch((e) => e);
  const returnToURL = new URL(`${baseURL}${returnTo ? returnTo : ''}`);
  /**
   * If sent here from a password-protected page
   * try and get email and token from the `returnTo` URL
   */
  const finalEmail = returnToURL.searchParams.has('email')
    ? returnToURL.searchParams.get('email')
    : email;
  const userForgotPassword = resolvedUrl.includes('forgot-password');
  const finalSetPasswordToken = returnToURL.searchParams.has(
    'set_password_token',
  )
    ? returnToURL.searchParams.get('set_password_token')
    : set_password_token;

  if (accessToken) {
    return {
      redirect: {
        destination: returnTo || 'dashboard/advisors',
        permanent: false,
      },
    };
  }

  if (resolvedUrl.includes('/login') && !createPassFlow)
    return {
      props: {
        type: LoginType.LOGIN,
      },
    };

  const tokenValidationBody: TokenValidationBody = {
    token: (finalSetPasswordToken || token) as string,
    email: finalEmail as string,
  };

  if (createPassFlow) {
    tokenValidationBody.source = 'account_opening';
  }

  const hasSetPassword = await request(
    `${baseURL}/api/core-cc/users/password/${finalEmail}`,
  )
    .then((res: {has_set_password: boolean}) => res.has_set_password)
    .catch(() => false);

  // If user `hasSetPassword`, render login UI
  if (hasSetPassword && !userForgotPassword) {
    return {
      props: {
        type: LoginType.LOGIN,
      },
    };
  }

  const tokenIsValid = await request(
    `${baseURL}/api/core-cc/password/validate`,
    {
      method: 'POST',
      headers,
      body: tokenValidationBody,
    },
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  // If token is invalid and user has to set password, render expired token UI
  if (!tokenIsValid && createPassFlow) {
    return {
      props: {
        type: LoginType.EXPIRED_TOKEN,
      },
    };
  }

  // if user has to reset/create password
  return {
    props: {
      type: LoginType.RESET,
    },
  };
};
