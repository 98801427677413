import React, {FC, useState} from 'react';
import Image from 'next/image';
import {useRouter} from 'next/router';

import {Button, renderUtils} from '@zoefin/design-system';

import request from '../../../../../utils/request';

import {checkStyle, COMP_NAME} from './styles';

interface CheckEmailProps {
  email: string;
}

const TIMER_SECONDS = 30;
let COUNTDOWN_INTERVAL: NodeJS.Timeout;

const CheckEmail: FC<CheckEmailProps> = ({email}) => {
  const router = useRouter();

  const [timer, setTimer] = useState<number>();

  const startTimer = () => {
    setTimer(TIMER_SECONDS);

    // Prevent two instances running simultaneously
    if (COUNTDOWN_INTERVAL) {
      clearInterval(COUNTDOWN_INTERVAL);
    }

    let counter = TIMER_SECONDS;

    COUNTDOWN_INTERVAL = setInterval(() => {
      if (counter > 0) {
        setTimer((prev) => {
          counter = prev - 1;

          return counter;
        });
      } else {
        clearInterval(COUNTDOWN_INTERVAL);
      }
    }, 1000);
  };

  const onResendEmail = () => {
    request(`/api/core-cc/password/email`, {
      method: 'POST',
      body: {email, returnTo: router.query?.returnTo},
    })
      .then(() => startTimer())
      .catch((e) => e);
  };

  renderUtils.useMount(onResendEmail);

  return (
    <>
      <div className={COMP_NAME}>
        <div className={`${COMP_NAME}__email-icon`}>
          <Image
            src="/static/svg/icon_email_blue_outline.svg"
            alt="Email Icon"
            width={76}
            height={56}
          />
        </div>
        <h3>Check Your Email</h3>
        <p className={`${COMP_NAME}__content`}>
          We sent you instructions to create your password.
        </p>
        <p className={`${COMP_NAME}__content`}>
          Haven&apos;t received the email?
          {!!timer && <strong>Resend in: {timer}s</strong>}
        </p>

        <div className={`${COMP_NAME}__button--mobile`}>
          <a onClick={() => onResendEmail()}>Resend Email</a>
        </div>

        <div className={`${COMP_NAME}__button`}>
          <Button
            config={{
              color: 'blue',
              type: 'ghost',
            }}
            disabled={!!timer}
            onClick={() => onResendEmail()}
            style={{width: 'min-content', marginTop: '20px'}}
            as="button">
            Resend Email
          </Button>
        </div>

        <p className={`${COMP_NAME}__no-user`}>
          Not a user yet?{' '}
          <a onClick={() => router.push('/onboarding')}> Get Started</a>
        </p>
      </div>
      <style jsx>{checkStyle}</style>
    </>
  );
};

export default CheckEmail;
