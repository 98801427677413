import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

import {MOBILE_HEADER_SIZE} from '../../styles';

const {color, typography} = styles;

export const COMP_NAME = 'check-email';

export const checkStyle = css`
  .check-email {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #f7f6f2;
    margin: auto 0px 0px;
    padding: 32px 26px;
    width: 100%;
    height: ${MOBILE_HEADER_SIZE};

    h3 {
      margin: 0 auto;
      font-weight: ${typography.weight.medium};
      font-size: ${typography.size.m2};
      line-height: 28px;
      color: ${color.darker};
    }

    &__email-icon {
      margin: 0 auto 16px;
    }

    &__content {
      font-size: ${typography.size.s3};
      line-height: 19px;
      color: ${color.dark};
      margin: 16px 0;
      text-align: center;

      strong {
        margin-left: 8px;
        font-weight: ${typography.weight.medium};
        color: ${color.darker};
      }
    }

    &__button {
      display: none;

      &--mobile {
        display: block;
        cursor: pointer;
        margin-top: 4px;
        color: ${color.bluePrimary};
        font-weight: ${typography.weight.medium};
      }
    }

    &__no-user {
      margin: auto 0 0;
      font-size: ${typography.size.s3};
      line-height: 19px;
      color: ${color.dark};

      a {
        color: ${color.dark};
        font-weight: ${typography.weight.medium};
      }
    }
  }

  @media screen and (min-width: 768px) {
    .check-email {
      margin: 0 auto;
      background-color: #ffffff;
      box-shadow: 0px 12px 32px rgba(29, 35, 58, 0.1);
      height: fit-content;
      max-height: 486px;
      max-width: 504px;
      padding: 48px 72px 42px;
      border-radius: 10px;

      h3 {
        margin: 0 auto 12px;
      }

      &__description {
        display: none;
      }

      &__reset-button {
        margin: 20px 0 0;
      }

      &__button {
        display: block;

        &--mobile {
          display: none;
        }
      }

      &__no-user {
        position: absolute;
        bottom: 0;
        margin: 0;
        transform: translateY(50px);
      }
    }
  }
`;
