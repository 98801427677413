import css from 'styled-jsx/css';

// This constant is used to define the size of the header on mobile
// and all views has the same size
export const MOBILE_HEADER_SIZE = 'calc(100% - 72px)';

export const COMP_NAME = 'password-login';

export const loginPasswordGlobalStyle = css.global`
  .password-login {
    &__back {
      position: absolute;
      top: 16px;
      left: 24px;

      &--hide {
        display: none;
      }
    }

    &__zoe-icon {
      position: absolute;
      display: none;
      width: 72px;
      height: 32px;

      &--left-mobile {
        display: block;
        top: 24px;
        left: 26px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .password-login {
      background-color: #f7f6f2;

      &__back {
        position: absolute;
        top: 38px;
        left: 48px;

        &--hide {
          display: block;
        }
      }

      &__zoe-icon {
        display: block;
        top: 32px;
        right: 32px;
        width: 91px;
        height: 40px;

        &--left-mobile {
          display: none;
        }
      }
    }
  }
`;

export const loginPasswordStyle = css`
  .password-login {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    overflow: auto;
    background-color: #f7f6f2;
  }

  @media screen and (min-width: 768px) {
    .password-login {
      flex-direction: row;
      justify-content: center;
      gap: 24px;
      padding: 130px 80px 74px 80px;
    }
  }

  @media screen and (min-width: 1280px) {
    .password-login {
      padding: 130px 170px 74px 200px;
    }
  }
`;
