import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const COMP_NAME = 'zoe-banner';

export const zoeBanner = css`
  .zoe-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 24px 26px;
    box-sizing: border-box;

    &__logo {
      width: 71px;
      height: 32px;
    }

    &__title {
      margin: 25px auto 0;
      font-family: ${typography.type.titles};
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      color: #475067;
    }

    &__footer {
      display: none;
      font-family: ${typography.type.primary};
      font-weight: ${typography.weight.regular};
      font-size: ${typography.size.s1};
      color: ${color.mediumDark};

      ul {
        display: flex;
        margin: 0;
        padding: 0;
        gap: 16px;
      }

      li {
        display: inline-block;

        a {
          text-decoration: none;
          font-family: ${typography.type.primary};
          font-weight: ${typography.weight.regular};
          font-size: ${typography.size.s1};
          color: ${color.mediumDark};

          &:hover {
            color: ${color.dark};
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .zoe-banner {
      justify-content: space-between;
      padding: 0;
      width: 50%;
      max-width: 500px;
      max-height: 555px;

      &__logo {
        width: 128px;
        height: 56px;
      }

      &__title {
        margin: 0;
        font-size: 60px;
        line-height: 86px;
      }

      &__footer {
        display: block;
      }
    }
  }
`;
