import React, {FC, useState} from 'react';
import Image from 'next/image';

import {Button, Input} from '@zoefin/design-system';

import request from '../../../../../utils/request';
import {clearAuth0Cookies} from '../../utils';

import {COMP_NAME, recoverStyle} from './styles';

interface RecoverFormProps {
  onResetPassword: () => void;
}

const RecoverForm: FC<RecoverFormProps> = ({onResetPassword}) => {
  const [email, setEmail] = useState<string>();
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const resetPassword = () => {
    // Clear Auth0 cookies
    clearAuth0Cookies();

    request(`/api/core-cc/password/email`, {
      method: 'POST',
      body: {email},
    })
      .then(() => setSent(true))
      .catch(() => setError(true));
  };

  const onInputErrorMsg = error
    ? 'This email doesn’t exit in our database, please check the email.'
    : '';

  return (
    <>
      {!sent && (
        <div className={COMP_NAME}>
          <h3>Reset Your Password</h3>
          <p className={`${COMP_NAME}__content`}>
            We will email you instructions on how to reset your password
          </p>
          <Input
            id="email-input"
            type="email"
            label="Email"
            value={email}
            onChange={(nVal) => {
              setEmail(nVal);
              setError(false);
            }}
            errorMessage={onInputErrorMsg}
            validator={(_newVal, valid) => valid}
            onValidationChange={(isValid) => {
              setValidEmail(email.length && isValid);
            }}
            onKeyUp={(code) => {
              if (code === 'Enter' && email) {
                resetPassword();
              }
            }}
            hasError={error}
            fitParent
            required
          />
          <div className={`${COMP_NAME}__reset-button`}>
            <Button
              disabled={!validEmail}
              config={{
                color: 'blue',
                type: 'primary',
              }}
              onClick={resetPassword}
              style={{width: '100%'}}
              as="button">
              Reset Password
            </Button>
          </div>
        </div>
      )}
      {sent && (
        <div className={`${COMP_NAME} ${COMP_NAME}--full-screen`}>
          <div className={`${COMP_NAME}__check-icon`}>
            <Image
              src="/static/svg/icon-circled-green-check-outline.svg"
              alt="Check Icon"
              width={56}
              height={56}
            />
          </div>
          <p className={`${COMP_NAME}__content ${COMP_NAME}__content--success`}>
            We sent you the reset password link!
            <br /> Please check your email.
          </p>
          <div className={`${COMP_NAME}__got-it-button`}>
            <Button
              config={{
                color: 'blue',
                type: 'primary',
              }}
              onClick={onResetPassword}
              style={{width: '100%', marginTop: '20px'}}
              as="button">
              Got it
            </Button>
          </div>
        </div>
      )}
      <style jsx>{recoverStyle}</style>
    </>
  );
};

export default RecoverForm;
