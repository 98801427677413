import React, {FC} from 'react';
import Image from 'next/image';

import styles from './styles.module.scss';

const ExpiredToken: FC = () => (
  <div className={styles.expiredToken} data-testid="login-expired-token">
    <div className={styles.expiredToken__logo}>
      <Image
        src="/static/img/zoe-logo/zoe_logo_primary.png"
        alt="zoe logo"
        width={110}
        height={48}
      />
    </div>

    <div className={styles.expiredToken__container}>
      <Image
        src="/static/svg/icon-orange-warning-outline.svg"
        alt="check icon"
        width={72}
        height={72}
      />
      <h3>This link has expired, please check your email for a new link. </h3>
      <p>You can close this window.</p>
    </div>
  </div>
);

export default ExpiredToken;
