import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

import {MOBILE_HEADER_SIZE} from '../../styles';

const {color, typography} = styles;

export const COMP_NAME = 'recover';

export const recoverStyle = css`
  .recover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #f7f6f2;
    padding: 32px 26px 48px;
    margin: auto 0 0;
    width: 100%;
    height: ${MOBILE_HEADER_SIZE};

    &--full-screen {
      height: 100%;
    }

    h3 {
      margin: 0 auto;
      font-weight: ${typography.weight.medium};
      font-size: ${typography.size.m2};
      line-height: 28px;
      color: ${color.darker};
    }

    &__reset-button,
    &__got-it-button {
      width: 100%;
      margin: auto 0 0;
    }

    &__check-icon {
      margin: auto auto 0;
    }

    &__content {
      font-size: ${typography.size.s3};
      line-height: 19px;
      color: ${color.dark};
      margin: 16px 0 24px;
      text-align: center;

      &--success {
        font-weight: ${typography.weight.medium};
        font-size: ${typography.size.m1};
        line-height: 24px;
        color: ${color.green};
        margin: 16px 0 auto;
      }
    }

    br {
      display: block;
      content: '';
      height: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    .recover {
      margin: 0 auto;
      background-color: #ffffff;
      box-shadow: 0px 12px 32px rgba(29, 35, 58, 0.1);
      height: fit-content;
      max-height: 486px;
      max-width: 504px;
      border-radius: 10px;
      padding: 48px 72px;

      &__check-icon {
        margin: 0 auto;
      }

      h3 {
        margin: 0 auto;
      }

      br {
        display: none;
      }

      &__reset-button {
        margin: 20px 0 0;
      }

      &__got-it-button {
        margin: initial;
      }

      &__content {
        margin: 16px 0 24px;
      }
    }
  }
`;
