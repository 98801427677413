import React, {Component} from 'react';
import {withAuth0, WithAuth0Props} from '@auth0/auth0-react';
import cn from 'classnames';
import Image from 'next/image';
import {NextRouter, withRouter} from 'next/router';

import {
  Button,
  Input,
  Notification,
  ProgressBar,
  styles as dsStyles,
} from '@zoefin/design-system';
import {isPasswordValidationsValid, Validators} from '@zoefin/utils';

import request from '../../../../../utils/request';
import {clearAuth0Cookies, onLogin} from '../../utils';

import styles from './styles.module.scss';

interface ResetPasswordProps extends WithAuth0Props {
  isCreate?: boolean;
  onReset: () => void;
  // We should use `WithRouterProps` BUT is a type not interface and cannot be extended
  router: NextRouter;
}
interface ResetPasswordState {
  password: string;
  confirmPassword: string;
  passwordValidators: Validators;
  sent: boolean;
  loading: boolean;
  failedError: string | boolean;
}

class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = {
      password: undefined,
      confirmPassword: undefined,
      passwordValidators: {
        length: undefined,
        uppercase: undefined,
        lowercase: undefined,
        number: undefined,
        all: undefined,
      },
      sent: false,
      loading: false,
      failedError: false,
    };
  }

  handlePasswordChange = (newVal: string) => {
    // Clear Auth0 cookies
    clearAuth0Cookies();

    const validators = isPasswordValidationsValid(newVal);

    this.setState({
      passwordValidators: validators,
      password: newVal,
    });
  };

  handleConfirmPasswordChange = (value: string) => {
    this.setState({confirmPassword: value});
  };

  resetPassword = () => {
    const {password, confirmPassword} = this.state;
    const {auth0, router, isCreate, onReset} = this.props;
    const URIObj = new URL(
      `${window.location.protocol}//${window.location.hostname}${router.query.returnTo}`,
    );
    let finalToken = (router.query.set_password_token ||
      router.query.token) as string;
    let finalEmail = router.query.email as string;

    this.setState({loading: true, failedError: false});

    if (!finalToken && router.query.returnTo) {
      finalToken = URIObj.searchParams.get('set_password_token');
    }

    if (!finalEmail && router.query.returnTo) {
      finalEmail = URIObj.searchParams.get('email');
    }

    request(`/api/core-cc/password/reset`, {
      method: 'POST',
      body: {
        token: finalToken,
        password,
        password_confirmation: confirmPassword,
        email: finalEmail,
      },
    })
      .then(async () => {
        if (!isCreate) {
          this.setState({loading: false});

          onReset();

          return;
        }

        this.setState({loading: false, sent: true});

        let authorizeUrl: string;

        await auth0
          .loginWithRedirect({
            openUrl(url) {
              authorizeUrl = url;
            },
          })
          .catch((e) => e);

        await onLogin({
          email: finalEmail,
          password,
          redirectTo: router.query.returnTo as string,
          authorizeUrl,
          onFailCallback: () => this.setState({sent: false}),
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          failedError: typeof err === 'string' ? err : true,
        });
      });
  };

  render() {
    const {
      password,
      confirmPassword,
      passwordValidators,
      failedError,
      sent,
      loading,
    } = this.state;
    const {isCreate} = this.props;
    let errorMessage = 'There was an error, please click the button again.';
    const labels = {
      title: isCreate ? 'Create Your Password' : 'Reset Your Password',
      button: isCreate ? 'Create Password' : 'Reset Password',
      success: isCreate
        ? ' Password successfully created!'
        : ' Password Reset successfully!',
    };

    if (typeof failedError === 'string') {
      errorMessage = failedError;
    }

    return (
      <>
        {!sent && (
          <div className={styles.reset}>
            <h3>{labels.title}</h3>

            <p className={styles.reset__description}>
              Log in to your account with your email address.
            </p>

            <Input
              id="password"
              label="Password"
              value={password}
              iconPosition="right"
              type="password"
              onChange={this.handlePasswordChange}
              hasError={
                passwordValidators.all !== undefined && !passwordValidators.all
              }
              className={cn({
                [styles.reset__passwordInput__modSuccess]:
                  passwordValidators.all === true,
              })}
              style={{maxWidth: 'initial'}}
              fitParent
              required
            />

            <ul className={styles.reset__errors}>
              <li
                className={cn(styles.reset__errors_error, {
                  [styles.reset__errors_error__modSuccess]:
                    passwordValidators.length,
                  [styles.reset__errors_error__modHasError]:
                    passwordValidators.length !== undefined &&
                    !passwordValidators.length,
                })}>
                Must be at least 8 characters.
              </li>
              <li
                className={cn(styles.reset__errors_error, {
                  [styles.reset__errors_error__modSuccess]:
                    passwordValidators.uppercase,
                  [styles.reset__errors_error__modHasError]:
                    passwordValidators.uppercase !== undefined &&
                    !passwordValidators.uppercase,
                })}>
                Must contain an Upper case.
              </li>
              <li
                className={cn(styles.reset__errors_error, {
                  [styles.reset__errors_error__modSuccess]:
                    passwordValidators.lowercase,
                  [styles.reset__errors_error__modHasError]:
                    passwordValidators.lowercase !== undefined &&
                    !passwordValidators.lowercase,
                })}>
                Must contain a Lower case.
              </li>
              <li
                className={cn(styles.reset__errors_error, {
                  [styles.reset__errors_error__modSuccess]:
                    passwordValidators.number,
                  [styles.reset__errors_error__modHasError]:
                    passwordValidators.number !== undefined &&
                    !passwordValidators.number,
                })}>
                Must contain a number.
              </li>
            </ul>

            <Input
              id="confirm-password"
              label="Confirm Password"
              value={confirmPassword}
              iconPosition="right"
              type="password"
              validator={(newVal, valid) => newVal.length > 7 && valid}
              onChange={this.handleConfirmPasswordChange}
              style={{width: '100%', maxWidth: 'initial'}}
              hasError={confirmPassword && password !== confirmPassword}
              errorMessage="Passwords must match."
              fitParent
              required
            />

            {!!failedError && (
              <Notification
                message={errorMessage}
                layout="small"
                noIcon
                style={{
                  maxWidth: '409px',
                  marginTop: '16px',
                }}
                type="error"
              />
            )}

            <div className={styles.reset__footer}>
              <Button
                disabled={
                  !passwordValidators.all ||
                  !confirmPassword ||
                  password !== confirmPassword
                }
                config={{
                  color: 'blue',
                  type: 'primary',
                }}
                loading={loading}
                onClick={this.resetPassword}
                style={{width: '100%', marginTop: '30px'}}
                as="button">
                {labels.button}
              </Button>

              <p className={styles.reset__copy}>
                By setting your password you agree to Zoe&apos;s{' '}
                <a
                  href="https://zoefin.com/terms-of-use/"
                  target="_blank"
                  rel="noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://zoefin.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer">
                  Privacy Policy.
                </a>
              </p>
            </div>
          </div>
        )}
        {sent && (
          <div className={cn(styles.reset, styles.reset__modFullScreen)}>
            <div className={styles.reset__checkIcon}>
              <Image
                src="/static/svg/icon-circled-green-check-outline.svg"
                alt="Check Icon"
                width={56}
                height={56}
              />
            </div>
            <p className={cn(styles.reset, styles.reset__content__modSuccess)}>
              {labels.success}
            </p>

            <ProgressBar
              width="280px"
              height="16px"
              containerColor={dsStyles.color.light}
              progressColor={dsStyles.color.green}
              animationDurationSeconds={3}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withAuth0(ResetPassword));
