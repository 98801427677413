import auth0 from 'auth0-js';

type Auth0JSProps = {
  authorizeUrl: string;
  redirectTo?: string;
  match?: boolean;
};

/**
 * Creates a new instance of the Auth0 WebAuth class with the provided configuration options.
 * @param {string} authorizeUrl - Authorization URL obtained with `loginWithRedirect` method from `@auth0/auth0-react`
 * @param {string} redirectTo - The URL you must return to after the authentication process. ie. `/dashboard/advisors`
 * @returns An object containing the WebAuth instance and the nonce string.
 */
const auth0JS = ({authorizeUrl, redirectTo, match}: Auth0JSProps) => {
  const url = new URL(authorizeUrl);

  /**
   * Structure of the `authorizeUrl` string:
   *
   * https://auth.domain.com/authorize?                  // AUTH0_ISSUER_BASE_URL
   * client_id=abc123                                    // AUTH0_CLIENT_ID
   * &scope=openid+profile+email+offline_access          // AUTH0_SCOPE
   * &audience=domain.com                                // AUTH0_AUDIENCE
   * &redirect_uri=https%3A%2F%2Fdomain.com%2Fcallback
   * &response_type=code
   * &response_mode=query
   * &state=abc123
   * &nonce=abc123
   * &code_challenge=abc123
   * &code_challenge_method=S256
   * &auth0Client=abc123
   */

  const state = url.searchParams.get('state');
  const nonce = url.searchParams.get('nonce');
  const urlReturnTo = url.searchParams.get('returnTo');
  const redirectUriParam = url.searchParams.get('redirect_uri');
  let redirectUri = `${redirectUriParam}`;

  if (redirectTo && !urlReturnTo) {
    redirectUri += `?returnTo=${redirectTo}`;
  }

  if (match && urlReturnTo) {
    redirectUri += `%26match=${match}`;
  }

  if (match && !urlReturnTo) {
    redirectUri += `?match=${match}`;
  }

  const WebAuth = new auth0.WebAuth({
    domain: url.origin,
    clientID: url.searchParams.get('client_id'),
    audience: url.searchParams.get('audience'),
    scope: url.searchParams.get('scope'),
    responseType: url.searchParams.get('response_type'),
    responseMode: url.searchParams.get('response_mode'),
    redirectUri,
    state,
  });

  return {WebAuth, nonce};
};

export default auth0JS;
