import o,{keyframes as r,css as i}from"styled-components";import{color as e}from"../../../shared/styles.js";const t="ZUIProgressBar",s=r`
  0% { width: 0%; }
  100% { width: 100%; }
`,n=o.div`
  position: relative;
  width: ${({width:o})=>o||"100%"};
  height: ${({height:o})=>o||"16px"};
  background-color: ${({containerColor:o})=>o||e.blueLighter};
  border-radius: ${({borderRadius:o})=>o??100}px;
  overflow: hidden;

  .${t}__progress {
    position: absolute;
    left: 0;
    width: ${({progress:o})=>o??100}%;
    height: 100%;
    background-color: ${({progressColor:o})=>o||e.bluePrimary};
    border-radius: ${({borderRadius:o})=>o??100}px;
    transition: width 0.2s ease;
    ${({progress:o,animationDurationSeconds:r,animationTimingFunction:e})=>"number"==typeof o?"":((o,r)=>i`
  animation: ${s} ${o}s
    ${r};
`)(r,e)};
  }
`;export{t as COMP_NAME,n as Container};
