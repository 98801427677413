import React, {FC} from 'react';
import Image from 'next/image';

import {COMP_NAME, zoeBanner} from './styles';

const ZoeBanner: FC = () => {
  return (
    <>
      <div className={COMP_NAME}>
        <div className={`${COMP_NAME}__logo`}>
          <Image
            src="/static/svg/zoe_logo_dark_blue.svg"
            alt="Zoe logo"
            width={128}
            height={56}
          />
        </div>

        <h3 className={`${COMP_NAME}__title`}>Your Way to Wealth</h3>

        <div className={`${COMP_NAME}__footer`}>
          <ul>
            <li>
              <a
                href="https://zoefin.com/privacy-policy/"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://zoefin.com/terms-of-use/"
                target="_blank"
                rel="noreferrer">
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href="https://zoefin.com/contact/"
                target="_blank"
                rel="noreferrer">
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="https://zoefin.com/solicitation-disclosure/"
                target="_blank"
                rel="noreferrer">
                Disclosures
              </a>
            </li>
          </ul>
          <p>
            Copyright © {new Date().getFullYear()} Zoe Financial, Inc. | All
            rights reserved
          </p>
        </div>
      </div>

      <style jsx>{zoeBanner}</style>
    </>
  );
};

export default ZoeBanner;
